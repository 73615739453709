import { computed } from '@angular/core';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';

import { Credential, CredentialsState } from './credentials.types';

const credentialsInitialState: CredentialsState = {
	credentials: {},
	selectedCredentialId: null,
};

export const CredentialsStore = signalStore(
	{ providedIn: 'root' },
	withState(credentialsInitialState),
	withComputed((store) => ({
		credentialsList: computed(() =>
			Object.values(store.credentials()).map((credential) => store.credentials()[credential.id]),
		),
		selectedCredential: computed(() => store.credentials()[store.selectedCredentialId() as string]),
	})),
	withMethods((store) => ({
		reset() {
			patchState(store, credentialsInitialState);
		},
		setSelectedCredential(id: string) {
			patchState(store, (state) => ({ ...state, selectedCredentialId: id }));
		},
		updateCredential(id: string, credential: Partial<Credential>) {
			patchState(store, (state) => ({
				...state,
				credentials: {
					...state.credentials,
					[id]: { ...(state.credentials[id] || {}), ...credential },
				},
			}));
		},
	})),
);
